$(document).on('turbolinks:load', function() {  
  const element = document.getElementById("videos-player");

  if (element) {
    var player = videojs('#videojs-player', {
      autoplay: true,
      controls: true,
      fluid: true
    });
  }

});
